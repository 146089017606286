import React from 'react';
import cn from 'classnames';
import Image, { ImageProps } from 'next/image';
import { HTMLMotionProps, motion } from 'framer-motion';

import styles from './Card.module.scss';

interface CardProps extends Omit<HTMLMotionProps<'div'>, 'title'> {
  title: React.ReactNode;
  image?: ImageProps;
  centerContent?: boolean;
  description: React.ReactNode;
  footer?: React.ReactNode;
  header?: React.ReactNode;
  labels?: React.ReactNode;
}

export default function Card({
    title,
    footer,
    image,
    labels,
    header,
    className,
    description,
    centerContent,
    ...props
}: CardProps) {
    return (
        <motion.div { ...props } className={ cn(styles.container, className && className) }>
            {image
                && <div className={ styles.header }>
                    <figure>
                        <Image
                            layout='fill'
                            className='image'
                            objectFit='cover'
                            placeholder='blur'
                            draggable={ false }
                            alt={ typeof title === 'string' ? title : '' }
                            { ...image }
                        />
                    </figure>
                    {header && header}
                </div>
            }

            <main className={ cn(styles.content, centerContent && styles.content__centered) }>
                <div className={ styles.content__header }>
                    <div className={ styles.title }>
                        { title }
                    </div>
                    { labels
                      && <div className={ styles.content__header_extra }>
                          { labels }
                      </div>
                    }
                </div>

                <div className={ `${ styles.description } font10` }>
                    { description }
                </div>
            </main>

            { footer
              && <div className={ styles.footer }>
                  { footer }
              </div>
            }
        </motion.div>
    );
}