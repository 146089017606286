import axios from 'axios';
import Head from 'next/head';
import React, { useRef } from 'react';
import { GetStaticProps } from 'next';
import { config } from '@fortawesome/fontawesome-svg-core';

import { MetaAPI } from '../../API/MetaAPI';
import { HomePagePost } from '../../API/EntityTypes/Blog';
import PlayGamesGrid from '../../components/views/Play/PlayGamesGrid';
import { AdoptionDetails } from '../../API/EntityTypes/AdoptionDetails';
import MainLandingView, { MainLandingViewProps } from '../../components/views/Landing/MainLandingView';

config.autoAddCss = false;

export interface GamesPageProps {
    homeInfo: HomePagePost;
    adoptions: Array<AdoptionDetails>;
    carbon: MainLandingViewProps['carbon'];
}

function GamesPage({
    carbon,
    homeInfo,
    adoptions,
}: GamesPageProps) {
    const gamesRef = useRef<HTMLDivElement>();

    return (
        <>
            <Head>
                <title>Play NFT Games and Participate in Gaming Experiences | MyMetaverse</title>
                <meta name="description" content="MyMetaverse provides unique gaming experiences utilizing NFTs while setting the pace for environmentally friendly metaverses." />
            </Head>

            <MainLandingView carbon={ carbon } gamesRef={ gamesRef } homeInfo={ homeInfo } />
            <PlayGamesGrid containerRef={ gamesRef } adoptions={ adoptions } />
        </>
    );
}

export const getStaticProps: GetStaticProps = async () => {
    /* GAMES */
    const adoptions = await MetaAPI.createRaw().getAdoptions().removeAuthorization().submit();

    /* Customizable content */
    const homeInfo = await axios.get<HomePagePost>('https://cms.mymetaverse.io/home');

    /* Carbon statistics */
    const availableCarbon = await MetaAPI.createRaw().getPlayToEarn().getBank().removeAuthorization().submit();
    const banks = await MetaAPI.createRaw().getCarbonBanks().removeAuthorization().submit();
    const totalEarnedByP2E = await MetaAPI.createRaw().getPlayToEarn().getTotalEarned().removeAuthorization().submit();

    return {
        props: {
            adoptions,
            homeInfo: homeInfo.data,
            carbon: {
                lockedInNFTs: banks.tokens,
                available: availableCarbon.balance,
                totalEarned: totalEarnedByP2E.totalEarned,
            },
        },
        revalidate: 3600,
    };
};

export default GamesPage;