import styles from './BinaryDeco.module.scss';
import React from 'react';

interface BinaryDecoProps {
    lines: Array<number>,
    className?: string
}

const chars = '10';

function randomString(value: number): string {
    let str = '';
    for (let i = 0; i < value; i++)
        str += chars.charAt(Math.random() * chars.length);
    return str;
}

export default function BinaryDeco({ lines, className }: BinaryDecoProps) {
    return (
        <>
            <div className={ `${ styles.box } ${ className }` }>
                {lines.map(value => {
                    const result = randomString(value);
                    return <span key={ result + value }>
                        {result}
                    </span>;
                })}
            </div>
        </>
    );
}