import styles from './BarLineDeco.module.scss';

interface BarLineDecoProps {
    length: number,
    className?: string
}

function generateBoxes(amount: number) {
    const result = [];

    for (let i = 0; i < amount; i++)
        result.push(<img src="/line.svg" key={ Math.random() * i } alt="" />);


    return result;
}

export default function BarLineDeco({ length, className }: BarLineDecoProps) {
    return (
        <>
            <div className={ `${ styles.box } ${ className }` }>
                {generateBoxes(length)}
            </div>
        </>
    );
}