import cs from 'classnames';
import Link from 'next/link';

import Card from '../Card/Card';
import styles from './GameCard.module.scss';
import MetaButton from '../MetaButton/MetaButton';
import { StringUtils } from '../../../utils/StringUtils';
import AltMetaButton from '../AltMetaButton/AltMetaButton';
import { AdoptionDetails } from '../../../API/EntityTypes/AdoptionDetails';

interface GameCardProps {
    className?: string;
    game: AdoptionDetails;
    status?: 'linked' | 'unlinked' | 'loading';
}

export default function GameCard({
    game,
    status,
    className,
}: GameCardProps) {
    const gameSlug = game.name.toLowerCase().split(' ').join('-');

    return (
        <Card
            title={ game.name }
            header={ (
                <Link href={ `/games/${ gameSlug }?tab=play_and_earn` } passHref>
                    <a className={ styles.card_header } title="Go to game Play & Earn page.">
                        Go to game Play & Earn page.
                    </a>
                </Link>
            ) }
            description={ game.description }
            image={ {
                src: game.banner,
                blurDataURL: game.banner,
                alt: `${ game.name } Banner`,
            } }
            className={ className }
            labels={
                status && <div className={ cs(styles.status, 'font5', {
                    [styles.linked]: status === 'linked',
                    [styles.loading]: status === 'loading',
                    [styles.unlinked]: status === 'unlinked',
                }) }>
                    { StringUtils.capitalize(status) }
                </div>
            }
            footer={ (
                <>
                    <Link href={ `/games/${ gameSlug }?tab=content` } passHref>
                        <MetaButton type='button' value={ 'PLAY NOW' } />
                    </Link>
                    <Link href={ `/games/${ gameSlug }?tab=play_and_earn` } passHref>
                        <AltMetaButton type='button' value={ 'EARN NFTS' } />
                    </Link>
                </>
            ) }
        />
    );
}