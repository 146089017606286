import { toast } from 'react-toastify';

export class StringUtils {

    public static capitalize(value: string) : string {
        return value.substring(0, 1).toUpperCase() + value.substring(1);
    }

    public static fixUrl(url: string) {
        if (!url.startsWith('http://') && !url.startsWith('https://'))
            url = 'http://' + url;
        return url;
    }

    public static getMonthName(month: number) {
        const monthNames = [ 'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December' ];

        return monthNames[month];
    }

    public static dateFromObjectId(objectId: string) {
        return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
    }

    public static isExternalURL(url: string): { isExternal: boolean; path: string } | null {
        const urlregex = /[(http(s)?)://(www.)?a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&/=]*)/i;
        const mymetaverseIoRegex = /^(http|https):\/\/mymetaverse\.io(?<path>[-a-zA-Z0-9@:%_+.~#?&/=]*)/i;
        const pathRegex = /^(?<path>\/[-a-zA-Z0-9@:%_+~#?&/=]*)/i;

        const match = url.match(mymetaverseIoRegex);
        if (!match)
            return urlregex.test(url) ? {
                isExternal: true,
                path: url,
            } : pathRegex.test(url) ? {
                isExternal: false,
                path: url.match(pathRegex)?.groups?.path || '',
            } : null;
        return {
            isExternal: false,
            path: match.groups.path,
        };
    }

    public static copy(value: string) {
        navigator.clipboard.writeText(value).then(() => {
            toast.success('Copied to clipboard.', {
                autoClose: 2000,
            });
        }).catch(err => { // DEPRECATED in case of error
            console.warn('Async: Could not copy text: ', err);
            const textArea = document.createElement('textarea');
            textArea.value = value;

            // Avoid scrolling to bottom
            textArea.style.top = '0';
            textArea.style.left = '0';
            textArea.style.position = 'fixed';

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                const successful = document.execCommand('copy');

                if (successful)
                    toast.success('Copied to clipboard.', {
                        autoClose: 2000,
                    });
            } finally {
                document.body.removeChild(textArea);
            }
        });
    }

    public static joinPath(...paths: string[]) {
        let builder = '';
        for (let i = 0; i < paths.length; i++) {
            const path = paths[i];
            if (builder.endsWith('/') && path.startsWith('/'))
                builder += path.substring(1);
            else if (builder.length === 0 || builder.endsWith('/') || path.startsWith('/'))
                builder += path;
            else
                builder += '/' + path;
        }

        return builder;
    }

    public static buildMyMetaTokenUri(id: string, index = '{id}') : string {
        const base = process.env.NEXT_PUBLIC_TOKENS_SERVER || 'https://cloud.mymetaverse.io/token';
        return StringUtils.joinPath(base, `/${ id }/${ index }.json`);
    }

    public static isEnjinTokenURL(uri: string) {
        return (/^(http|https):\/\/(jumpnet\.)?cloud\.enjin\.io(.+\.json$)/).test(uri);
    }

    public static isMetaverseTokenURL(uri: string) {
        return (/^(http|https):\/\/(dev)?cloud\.mymetaverse\.io(.+\.json(.+)?$)/).test(uri);
    }

    public static isValidYoutubeVideo(url: string) {
        // eslint-disable-next-line no-useless-escape
        const ytRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

        return ytRegex.test(url);
    }

    public static getYoutubeVideoId(url: string) {
        if (!StringUtils.isValidYoutubeVideo(url))
            return undefined;

        // eslint-disable-next-line no-useless-escape
        const ytRegex = /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/;

        const match = ytRegex.exec(url);
        if (!match)
            return undefined;

        /*
            For example: https://www.youtube-nocookie.com/embed/VIDEO_ID?autoplay=1
            match[0] = https://www.youtube-nocookie.com/embed/VIDEO_ID?autoplay=1
            match[1] = https://
            match[2] = www.
            match[3] = youtube-nocookie.com
            match[4] = -nocookie
            match[5] = /embed/
            match[6] = VIDEO_ID
            match[7] = ?autoplay=1
        */

        // eslint-disable-next-line no-unused-vars
        const [ _link, _protocol, _subdomain, _domain, _domainExtra, _path, videoId ] = match;

        return videoId;
    }

    public static parseDate(
        date: number,
        options: Intl.DateTimeFormatOptions & { showYear?: boolean } = {},
    ) {
        if (options.showYear === undefined) options.showYear = false;

        const intl = new Intl.DateTimeFormat('en-US', {
            month: 'long',
            day: '2-digit',
            year: options.showYear ? 'numeric' : undefined,
            ...options,
        });

        return intl.format(date);
    }
}