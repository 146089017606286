import { Col, Row } from 'antd';
import React, { useCallback } from 'react';

import { MetaAPI } from '../../../API/MetaAPI';
import styles from './PlayGamesGrid.module.scss';
import GameCard from '../../common/GameCard/GameCard';
import DivBg from '../../common/DivWithBackground/DivBg';
import { useIsAuth } from '../../../contexts/AuthContext';
import BinaryDeco from '../../common/BinaryDeco/BinaryDeco';
import { LinkedApp } from '../../../API/EntityTypes/LinkedApp';
import BarLineDeco from '../../common/BarLineDeco/BarLineDeco';
import { AdoptionDetails } from '../../../API/EntityTypes/AdoptionDetails';

const _isLinked = (id: string, linked?: Array<LinkedApp>) => {
    if (!linked)
        return 'loading';

    const isLinked = linked.some(app => app.appId === id);
    return isLinked ? 'linked' : 'unlinked';
};

const mockedLinkedAdoptions = MetaAPI.getInstance().getLinkedApps();

export default function PlayGamesGrid({ containerRef, adoptions }: { containerRef: React.MutableRefObject<HTMLDivElement>, adoptions: Array<AdoptionDetails>}) {

    const isAuth = useIsAuth();
    const { data: linked } = mockedLinkedAdoptions.useSWR(isAuth)();
    const isLinked = useCallback((id: string) => isAuth ? _isLinked(id, linked) : 'unlinked', [ linked, isAuth ]);

    return (
        <section className={ `${ styles.container }` }>
            <div className='containerLength'>
                <Row className={ styles.decorationrow }>
                    <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 8 } xl={ 8 }>
                        <DivBg bgSrc={ '/logos/ball.png' } className={ styles.background }/>
                    </Col>
                    <Col className={ styles.barline } xs={ 24 } sm={ 24 } md={ 12 } lg={ 8 } xl={ 8 } ref={ containerRef }>
                        <BarLineDeco length={ 20 } className={ styles.bar1 } />
                    </Col>
                    <Col className={ styles.bindeco } xs={ 24 } sm={ 24 } md={ 12 } lg={ 8 } xl={ 8 }>
                        <BinaryDeco lines={ [ 58, 40, 55, 30 ] } />
                    </Col>
                </Row>
                <Row className={ styles.gamesCard } gutter={ [ 32, 32 ] }>
                    {
                        (adoptions || []).map((app, k) =>
                            <Col key={ k } xs={ 24 } sm={ 24 } md={ 12 } lg={ 8 } xl={ 8 }>
                                <GameCard
                                    game={ app }
                                    status={ isLinked(app.id) }
                                />
                            </Col>,
                        )
                    }
                </Row>
                <Row className={ styles.barlinerow }>
                    <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 8 } xl={ 8 }>
                        <BarLineDeco length={ 21 } />
                    </Col>
                    <Col xs={ 24 } sm={ 24 } md={ 12 } lg={ 8 } xl={ 8 }>
                        {/*    <MetaButton value='LOAD MORE' />*/}
                    </Col>
                    <Col xs={ 0 } sm={ 0 } md={ 0 } lg={ 8 } xl={ 8 }>
                        <BarLineDeco length={ 21 } />
                    </Col>
                </Row>
            </div>
        </section>
    );
}